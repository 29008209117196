import dayjs from "dayjs";

export const getIsOrderPast14Days = (date) => {
    if (date) {
        const currentDate = dayjs();
        const deliveryDatePlus14 = dayjs(date).add(0, "day");
        const isAfter = currentDate.isAfter(deliveryDatePlus14);

        return isAfter;
    }

    return false;
};

export const getIsOrderPast6Months = (date) => {
    if (date) {
        const currentDate = dayjs();
        const datePlus6Months = dayjs(date).add(6, "months");
        const isAfter = currentDate.isAfter(datePlus6Months);

        return isAfter;
    }

    return false;
};

export const getOrderName = (name, type) => {
    if (type === "REPEAT" && name) {
        if (name.includes("Repeat order - ")) {
            return `Repeat prescription - ${name?.split("Repeat order - ")[1]}`;
        }

        return `Repeat order - ${name}`;
    }

    return name;
};
